<template>
  <div class="total-purchase-statistics">
    <ChartTitle
      title="首購客總消費金額"
      :useRangeType="true"
      :getChart="getChart"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { generateFirstPurchaseConfig, getFirstPurchaseData } from '../../common'
import { usePermissions } from '@/use/permissions'
import { filterFeatureKeysBySource } from '@/utils/dashboard'

export default defineComponent({
  name: 'TotalPurchaseStatistics',
  components: { ChartTitle },
  emits: ['getTime'],
  setup (props, { emit }) {
    const { chartRef, updateChart } = useChart(generateFirstPurchaseConfig())
    const shopId = computed(() => store.getters.shop)
    const { checkAction } = usePermissions()

    const getChart = async ({ interval, splitType }) => {
      const { newOptions, lastUpdatedAt } = await getFirstPurchaseData({
        shopId: shopId.value,
        target: 'sumAmount',
        interval,
        splitType,
        typeKeys: filterFeatureKeysBySource(checkAction),
      })

      updateChart(newOptions)
      emit('getTime', lastUpdatedAt)
    }

    return {
      chartRef,
      getChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.total-purchase-statistics {
  @apply flex flex-col gap-5;
}
</style>
